import { groupBy, keyBy, mapValues, sortBy } from 'lodash'
import { DefaultValue, selector, selectorFamily, waitForAll } from 'recoil'

import { runbookPermission, runbookVersionMetaState } from '..'
import { filteredTaskListIdsState, taskListLookupState } from '../tasks/task-list'
import { currentUserState } from 'main/recoil/current-user'
import { StreamListStream } from 'main/services/queries/types'
import { filterSelector } from 'main/recoil/shared/filters'

export const streamsState = selector<StreamListStream[]>({
  key: 'streams:list',
  get: ({ get }) => {
    const streams = get(runbookVersionMetaState).streams
    return sortBy(streams, 'name')
  },
  set: ({ set }, newValue) => {
    return set(runbookVersionMetaState, prev => {
      if (newValue instanceof DefaultValue) return newValue

      return { ...prev, streams: newValue }
    })
  }
})

export const streamsFlattenedState = selector<StreamListStream[]>({
  key: 'streams:list:flat',
  get: ({ get }) => {
    const parentStreams = get(streamsState)
    return parentStreams.flatMap(stream => [stream, ...(stream.children ?? [])])
  }
})

export const streamsLookupState = selector<Record<number, StreamListStream>>({
  key: 'streams:lookup',
  get: ({ get }) => {
    return keyBy(get(streamsFlattenedState), 'id')
  }
})

export const streamsInternalIdLookupState = selector<Record<number, StreamListStream>>({
  key: 'streams:internal-id-lookup',
  get: ({ get }) => {
    return keyBy(get(streamsFlattenedState), 'internal_id')
  }
})

export const streamState = selectorFamily<StreamListStream, { id: number }>({
  key: 'streams:id',
  get:
    ({ id }) =>
    ({ get }) => {
      return get(streamsLookupState)[id]
    }
})

export const streamTaskCountsRecordState = selector<{ [key: string]: { filtered: number } }>({
  key: 'streams:task-count',
  get: ({ get }) => {
    const [streamsLookup, tasksLookup] = get(waitForAll([streamsLookupState, taskListLookupState]))

    const allTasks = Object.values(tasksLookup)
    const filteredIds = get(filteredTaskListIdsState)
    const filteredTasks = filteredIds.map(id => tasksLookup[id])

    const allTasksByStreamId = groupBy(allTasks, 'stream_id')
    const filteredTasksByStreamId = groupBy(filteredTasks, 'stream_id')

    return mapValues(streamsLookup, (stream, streamId) => {
      const filteredTasksForStreamCount = filteredTasksByStreamId[streamId]?.length ?? 0

      let streamChildCounts = { total: 0, filtered: 0 }

      if (stream.children?.length) {
        streamChildCounts = stream.children.reduce(
          (acc, child) => ({
            total: acc.total + allTasksByStreamId[child.id]?.length ?? 0,
            filtered: acc.filtered + (filteredTasksByStreamId[child.id]?.length ?? 0)
          }),
          { total: 0, filtered: 0 }
        )
      }

      return {
        filtered: filteredTasksForStreamCount + streamChildCounts.filtered
      }
    })
  }
})

export const streamsPermittedState = selector<StreamListStream[]>({
  key: 'streams:permitted',
  get: ({ get }) => {
    const streams = get(streamsFlattenedState)
    const currentUser = get(currentUserState)
    const canCreateTasks = get(runbookPermission({ attribute: 'update' }))

    if (!currentUser) {
      return []
    }

    if (canCreateTasks) return streams

    const permittedParentIds = streams.flatMap(s => (s.permissions.create_task.includes(currentUser.id) ? [s.id] : []))
    return streams.filter(
      s => permittedParentIds.includes(s.id) || (s.parent_id && permittedParentIds.includes(s.parent_id))
    )
  }
})

export const isStreamPermittedState = selectorFamily<boolean, { streamId?: number }>({
  key: 'streams:permitted-task-delete',
  get:
    ({ streamId }) =>
    ({ get }) => {
      const permittedStreams = get(streamsPermittedState)
      return !!permittedStreams.find(s => s.id === streamId)
    }
})

// Given the current filter params, and user permissions, return a stream the user can create in
export const newTaskStreamState = selectorFamily<number | undefined, { prevTaskStreamId?: number }>({
  key: 'streams:permitted-new-task-stream',
  get:
    ({ prevTaskStreamId }) =>
    ({ get }) => {
      const permittedStreams = get(streamsPermittedState)
      const streamFilterInternalIds = get(filterSelector({ attribute: 'stream' })) as number[]

      if (permittedStreams.length === 0) return
      let newTaskStream

      if (prevTaskStreamId) {
        const permittedNewTaskStream = permittedStreams.find(s => s.id === prevTaskStreamId)
        newTaskStream = permittedNewTaskStream || permittedStreams[0]
      } else if (
        streamFilterInternalIds &&
        Array.isArray(streamFilterInternalIds) &&
        streamFilterInternalIds.length > 0
      ) {
        // return the first one, out of the filtered ids, that the user has permission on
        const permittedNewTaskStream = permittedStreams.find(s => s.internal_id === streamFilterInternalIds[0])
        // If nothing returned, see if user has permission on ANY of the filtered stream ids, return first match.
        // Or default to first permitted stream.
        newTaskStream =
          permittedNewTaskStream ??
          (permittedStreams.find(s => streamFilterInternalIds.includes(s.internal_id)) || permittedStreams[0])
      } else {
        newTaskStream = permittedStreams.find(s => s.is_primary) || permittedStreams[0]
      }
      return newTaskStream?.id
    }
})
